<template>
  <div :class="{'p-6': hasBackofficeAccess}">
     <div class="vx-row" v-if="hasBackofficeAccess">
      <div class="vx-col w-full mb-3">
        <router-link
        class="decorated"
        :to="`/project/${id}`"
        >Regresar al proyecto </router-link>
      </div> 
    </div>
    
    <big-title v-if="isMounted && !isProgram">
      {{project.alias || "Pendiente" }} -<span> {{id}}.</span>
      <h5 class="mt-2">Integrar las evidencias para la tecnología - {{familyGroupName}}</h5>
      <p class="text-subtitle mt-6">Agrega los datos y documentos correspondientes a esta instalación.</p>

    </big-title>

    <!-- SECCIONES -->

    <div v-if="isMounted && !failed" >
      <vx-card v-if="!isProgram" title="Ubicación" class="mb-5">
        <p>{{full_address}}</p>
      </vx-card>
      <vx-card v-for="section in allSections" :key="section.id" :title="section.public_name" class="mb-5">
        <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>

        <div class="vx-row">
          <template v-for="f in section.fields">

            <!-- Campos automaticos -->
            <form-field 
              ref="allSections"
              v-if="isAutoFormField(f)"
              :key="f.id"
              :f="f"
              :dataContainer="payload"
              :collectionsRoot="collections"
              :onChangeReceptor="onFieldChange"
              :keepDisabled="isDisabled"
              :evaluator="evaluateCondition" 
              :disabled="isProgram"/>
            
            <!-- fg_reqs -->
            <family-group-reqs
              :key="f.id"
              :section_id="section.id"
              :family_group_id="familyGroupId"
              :project_fg_location_id="fg_id"
              :isDisabled="isDisabled"
              ref="family_group_reqs"         
              v-else-if="f.fname == 'family_group_reqs'" />

          </template>
        </div>

        <template v-if="isMounted && section.documentFields.length > 0">
          <vs-divider></vs-divider>
          <h6 class="mb-3">Documentos</h6>
          <div class="vx-row">
            <document-upload
            v-for="df in section.documentFields"
            :key="df.id"
            :target_id="df.target_id"
            :f="df.formField"
            :doc="df.documentSet"
            :disabled="isDisabled"
            :from_project_id="id"
            :evaluator="evaluateCondition"
            :selfAccessToken="selfAccessToken"
            />
          </div>
        </template>

        <!-- Save Button -->
        <div class="vx-row" v-if="section.id == 56 && !isDisabled">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="saveTechnologyData()">Guardar cambios</vs-button>                    
            </div>
          </div>
        </div>

      </vx-card>
      
    </div>

    <load-error v-if="failed" />
    
  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import FormField from '@components/forms/FormField.vue';
import FamilyGroupReqs from '@components/forms/FamilyGroupReqs.vue';
import PgRequestEvidenceControl from '@technologies/PgRequestEvidenceControl.vue';

const addressSelectDefinitions = [ 
 // { id: 1, label: "Misma dirección del cliente", valid: true },
  { id: 2, label: "Elegir una dirección relacionada con el proyecto", valid: true },
  { id: 3, label: "Agregar otra dirección", valid: true },
];
export default {
  props: {
    id: { type: [Number, String], required: true },
    fg_id: { type: [Number, String], required: true },
    isProgram: { type: [Boolean], required: false, default: false },
    selfAccessToken: { type: String, default: null},
  },
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      errorCode: 0,
      onError: false,
      metaData: {},
      project: {},
      fgLocation: {},
      form_id: 24,
      sections: [56,42],

      form: {},
      allSections: [],
      full_address: null,

      loading: false,  
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,

      documentsStates: [],
      familyGroupName: null,
      allAddresses: [],
      familyGroupId: null,

      payload: {},
      
      collections: {
        existingLocations: [],
        countries: [],
        cities: [],
        family_groups: [],
        relationTypes: [],
      },

      addressSelectOptions: addressSelectDefinitions,

      clientAddress: null,
      location_id: null,

      validatedFields: [], 
    }
  },
  components: {
    FormField,
    FamilyGroupReqs,
    PgRequestEvidenceControl,
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getProjectInfo();
    await this.getFormInfo();
    this.setSectionGroups();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    isDisabled(){
      if(this.isProgram){
        return true
      }
      return this.project.status == 4 || this.project.status == 8 || this.hasBackofficeAccess;
    },
    pendingVerificationRequest(){
      return this.project.evidence_verification_request_date == null
    },
    validAddressSelectOptions(){
      return this.addressSelectOptions.filter(e => e.valid);
    },
    should_fill_new_location(){
      return this.payload.address_source == 3;
    },
    should_fill_not_owner_info(){
      return this.payload.location$ownership != true;
    },
    should_select_existing_location(){
      return this.payload.address_source == 2;
    },

    pg_location_exists(){
      return true;
    },
    project_fg_location_id(){
      return this.$props.fg_id;
    },

    /* validaciones por family_group para fg_reqs */
    should_fill_photovoltaic_system_evidences(){
      return this.familyGroupId == 1;
    },
    should_fill_solar_heater_system_evidences(){
      return this.familyGroupId == 2;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  methods: {
    async getRequestInfo(){
      let famGroup = this.collections.family_groups.find(f => f.id == this.fam_id);
      if(famGroup == null){
        this.failed = true;
      }
      else {
        this.familyGroupName = famGroup.name;
      }
    },
    async getProjectInfo() {
      try {
        let params = "with[]=projectFgLocations.familyGroup&with[]=projectFgLocations.location.address&with[]=client.user.personal.address";
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        this.onError = false;
        this.project = response.data;
        this.fgLocation = response.data.project_fg_locations.find(f => f.id == this.fg_id);
        this.familyGroupName = this.fgLocation.family_group.name;
        this.familyGroupId = this.fgLocation.family_group.id;
        this.location_id = this.fgLocation.location_id;
        this.full_address = this.nestedFieldValue(this.fgLocation, 'location.address.full_address');
      }
      catch (error) {
        this.processError(error.status);
      }
    },
    async getFormInfo(){
      try{
        await this.getSectionInfo(56);
        await this.getSectionInfo(42);

       this.allSections.forEach(s => {          
          s.documentFields = [];
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null, target_id: null };
              let dset = this.documentsStates.find(sid => sid.id == f.doc_id);
              if(dset){
                ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                ff.documentSet = dset;
              }              
              s.documentFields.push(ff);
            }
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);        
        let url = this.ApiDomain + `/storagev3/documents/section/${id}`;
        let response2 = await axios.get(url);
        if(response2.data){
          response2.data.forEach(f => {
            this.documentsStates.push(f);
          })
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getCollections(){
      try {
        let res3 = await axios.get('/api/v1/forms/getFamilyGroups')
        this.collections.family_groups = res3.data;
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },

    async saveTechnologyData() {
      let result = await this.runValidations();
      if(!result){
        this.missingFieldsNotif();
      }
      else{
        this.loading = true;
        let res = await this.saveFormInfo();
        this.loading = false;
        if(res == true){
          this.saveSuccessNotif();
        }
        else {
          this.failedOperationNotif(null, this.errorMssg);
        }
      }
    },

    async runValidations() {
       // validar radio

      let res1 = await this.validateFormFields();
      if(!res1){
        return false;
      }
      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }     

      // validar fg_reqs
      let res3 = await this.$refs.family_group_reqs[0].checkValidation();
      if(!res3){
        return false;
      }

      const urlInfo = await this.getURLMonitoringData();
      if(urlInfo == null){
        return false;
      } else {
        const urlResponse = await this.validate_url_access(urlInfo);
        if(!urlResponse){
          this.missingFieldsNotif(null, "No hemos recibido una respuesta positiva (200 - success) de la URL de monitoreo que nos proporcionaste.");
          return false;
        }
      }
      
      return true;
    },

    async validateFormFields(){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs["allSections"];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          let sp = f.specialValidation();
          if(sp != null){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
              continue;
            }
          }
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },

    async saveFormInfo(){
      let ok = false;
      try{
        let ff = this.$refs.family_group_reqs[0];
        await ff.saveFgReqs();
        ok = true;
      }
      catch(e){
        this.warn(e.response);
        ok = false;
        this.errorMssg = e.response.data ? e.response.data.error : null;
      }
      return ok;
    },

    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

     /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.project.id;        
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    processError(code){
      this.onError = true;
      this.errorCode = code;
    },
    setSectionGroups(){      
     // this.addressSelectOptions.find(f => f.id == 2).valid = this.collections.existingLocations.length > 0;      
    },

    async validate_fg_reqs(){
      return await this.$refs.family_group_reqs.validate();
    },

    async validate_url_access(url)
    {
      try {
        // The custom URL entered by user
        await fetch(url, {mode: 'no-cors'});
        return true;
      } catch (error) {
        return false
      }
    },
    getURLMonitoringData()
    {
      try {
        const fgreqs = this.$refs.family_group_reqs[0];
        const field = fgreqs.fgFormFields.find(x => x.field_name == "URL de monitoreo");
        const fgReqsContainer = fgreqs.fgReqsContainer;
        return fgReqsContainer[field.fname];
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  }
};
</script>

<style scoped>
  .alt-radio-text {
    margin-left: 10px;
    color: rgba(var(--vs-success), 1);
  }
</style>